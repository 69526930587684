exports.linkResolver = (doc) => {
  // URL for a Case Study type
  if (doc.type === 'case_study') {
      return `/case-studies/${doc.uid}`
  }

  // URL for the Our Vision type
  if (doc.type === 'our_vision') {
      return `/${doc.uid}`
      }

  // URL for the Contact type
  if (doc.type === 'contact') {
      return `/${doc.uid}`
      }

  // URL for a Page type
  if (doc.type === 'page') {
      return `/${doc.uid}`
  }

  // Backup for all other types
  return '/'
}

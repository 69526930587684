import * as React from 'react'
import {
  PrismicPreviewProvider,
  componentResolverFromMap,
} from 'gatsby-plugin-prismic-previews'

import { linkResolver } from './src/utils/link-resolver';
import IndexPage from './src/pages/index';
import ContactPage from './src/pages/contact';
import OurVisionPage from './src/pages/our-vision';
import CaseStudyTemplate from './src/pages/case-studies/{PrismicCaseStudy.uid}';

export const wrapRootElement = ({ element }) => (
  <PrismicPreviewProvider
    repositoryConfigs={[
      {
        repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
        linkResolver,
        componentResolver: componentResolverFromMap({
          homepage: IndexPage,
          contact: ContactPage,
          case_study: CaseStudyTemplate,
          our_vision: OurVisionPage,
        }),
      },
    ]}
  >
    {element}
  </PrismicPreviewProvider>
)

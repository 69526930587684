import * as React from 'react';
import { GatsbyImage } from "gatsby-plugin-image";
import * as styles from './four-points.module.css';

const OnePoint = ({
  title,
  support,
  photo,
  photoAlt,
}) => (
    <div className={styles.onePoint}>
      <GatsbyImage 
        image={photo} 
        alt={photoAlt} 
      />
      <h3 className={styles.onePointName}>{title}</h3>
      <span>{support}</span>
    </div>
  );

export default OnePoint;


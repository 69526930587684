import * as React from 'react';
import { Link } from 'gatsby';
import { PrismicRichText } from '@prismicio/react';
import { GatsbyImage } from "gatsby-plugin-image";

import * as styles from "../assets/styles/page-styles.module.css";

export const CaseStudyPreview = ({
  title,
  subTitle,
  tags,
  url,
  imageSrc,
  imageAlt,
}) => {
  return (
    <div className={styles.caseStudy}>
      <div className={styles.caseStudyTitle}>
        <h5>
          <strong>{title}</strong>
          <br />
          {subTitle}
        </h5>
        <PrismicRichText field={tags} />
      </div>
      <Link to={url}>
        <GatsbyImage image={imageSrc} alt={imageAlt} />
      </Link>
  </div>
  );
}

export default CaseStudyPreview;

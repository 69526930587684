import * as React from 'react';

import * as styles from '../assets/styles/page-styles.module.css';

const ContentBlock = ({children}) => {
  return(
    <div className={styles.contentBlock}>
      {children}
    </div>
  );
}

export default ContentBlock;

import React, { useRef } from 'react';

import { useButton } from '@react-aria/button';

import { linkButton } from './link-button.module.css';

function LinkButton(props) {
  let {children, ...rest} = props;
  let ref = useRef();

  let {buttonProps} = useButton(
    {
      ...props,
      elementType: 'a',
    },
    ref
  );

  return (
    <a
      className={linkButton}
      {...buttonProps}
      ref={ref}
      {...rest}>
      {children}
    </a>
  );
}

export default LinkButton;

import * as React from 'react';
import { GatsbyImage } from "gatsby-plugin-image";

import * as styles from './team-members.module.css';

const TeamMember = ({
  name,
  title,
  photo,
  photoAlt,
  profileLink,
}) => {
  return (
    <a href={profileLink} className={styles.teamMember}>
      <GatsbyImage image={photo} alt={photoAlt} />
      <span className={styles.teamMemberName}>{name}</span>
      <span>{title}</span>
    </a>
  );
}

export default TeamMember;

import * as React from 'react';
import { PrismicRichText } from '@prismicio/react';

import Container from '../../components/container';
import ContentBlock from '../../components/content-block';
import * as linkUtils from '../../utils/link-resolver';

import * as styles from '../../assets/styles/page-styles.module.css';

const RichText = ({
  slice
}) => {
  return (
    <section className={styles.section}>
      <Container>
        <ContentBlock>
          <PrismicRichText
            field={slice.primary.rich_text.richText}
            linkResolver={linkUtils.linkResolver}
          />
        </ContentBlock>
      </Container>
    </section>
  );
}

export default RichText;

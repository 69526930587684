import * as React from 'react';
import { Link } from 'gatsby';
import { PrismicRichText } from '@prismicio/react';
import { GatsbyImage } from "gatsby-plugin-image";

import * as linkUtils from '../utils/link-resolver';

import * as styles from "../assets/styles/page-styles.module.css";

export const CaseStudySlideshowItem = ({
  title,
  subTitle,
  tags,
  description,
  url,
  imageSrc,
  imageAlt="",
}) => {
  return (
    <div className={styles.carouselItem}>
      <div className={styles.carouselItemWrapper}>
        <div className={styles.carouselItemImage}>
          <Link to={url}>
            <GatsbyImage image={imageSrc} alt={imageAlt} />
          </Link>
        </div>
        <div className={styles.carouselItemTitle}>
          <h3>
            <strong>{title}</strong>
            <br />
            {subTitle}
          </h3>
          <PrismicRichText field={tags} />
        </div>
        <div className={styles.carouselItemSummary}>
          <PrismicRichText 
            field={description}
            linkResolver={linkUtils.linkResolver}
          />
        </div>
      </div>
    </div>
  );
}

export default CaseStudySlideshowItem;

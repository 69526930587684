import React from 'react';
import { graphql } from 'gatsby';
import { PrismicRichText } from '@prismicio/react';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';

import Layout from '../global/layout';

import CaseStudySlideshow from '../components/case-study-slideshow';
import Container from '../components/container';
import ContentBlock from '../components/content-block';
import LinkButton from '../components/link-button';
import { SliceZone } from '../components/slices';

import * as styles from '../assets/styles/page-styles.module.css';

const IndexPage = ({ data }) => {
  if (!data) return null;
  const doc = data.prismicHomepage.data;
  const caseStudies = data.allPrismicCaseStudy.nodes;
  return (
    <Layout>

      <section className={styles.hero}>
        <Container>
          <ContentBlock>
            <div className={styles.overlayQuote}>
              <PrismicRichText field={doc.hero_content.richText} />
            </div>
          </ContentBlock>
        </Container>
      </section>

      <section className={styles.section}>
        <Container>
          <ContentBlock>
            <PrismicRichText field={doc.intro_copy.richText} />
          </ContentBlock>
        </Container>
      </section>

      <CaseStudySlideshow caseStudySlides={caseStudies} />

      <SliceZone sliceZone={doc.body} />

      <section className={styles.section}>
        <Container>
          <ContentBlock>
            <h2>
              Shall we look under the hood?
            </h2>
            <br />
            <LinkButton href="/contact">Contact Us</LinkButton>
          </ContentBlock>
        </Container>
      </section>
    </Layout>
  );
};

export const query = graphql`
query HomePageQuery {
  prismicHomepage {
    _previewable
    data {
      body {
        ... on PrismicHomepageDataBodyBannerQuote {
          id
          slice_type
          primary {
            background_image {
              url
              alt
            }
            blockquote {
              richText
            }
            figcaption {
              richText
            }
          }
        }
        ... on PrismicHomepageDataBodyResponsiveImage {
          id
          slice_type
          primary {
            image {
              alt
              gatsbyImageData
            }
          }
        }
        ... on PrismicHomepageDataBodyTextContent {
          id
          slice_type
          primary {
            rich_text {
              richText
            }
          }
        }
      }
      hero_content {
        richText
      }
      hero_image {
        url
      }
      intro_copy {
        richText
      }
    }
  }
  allPrismicCaseStudy(sort: {order: ASC, fields: first_publication_date}) {
    nodes {
      type
      uid
      url
      data {
        description {
          richText
        }
        title
        subtitle
        tags {
          richText
        }
        preview_image {
          alt
          gatsbyImageData
        }
      }
    }
  }
}
`

export default withPrismicPreview(IndexPage);

// extracted by mini-css-extract-plugin
export var headerLayout = "nav-main-module--header-layout--SORpp";
export var logo = "nav-main-module--logo--bFirY";
export var menuToggleButton = "nav-main-module--menu-toggle-button--GIXRI";
export var navMain = "nav-main-module--nav-main--pYBq0";
export var headerLayoutOpen = "nav-main-module--header-layout-open--byoHq";
export var navMainActive = "nav-main-module--nav-main-active--WUWgF";
export var navLink = "nav-main-module--nav-link--UeGYG";
export var activeNavLink = "nav-main-module--active-nav-link--0kEIl";
export var active = "nav-main-module--active--3mGh+";
export var shade = "nav-main-module--shade--1iKBx";
import * as  React from 'react';

import CaseStudySlideshowItem from './case-study-slideshow-item';

import * as styles from '../assets/styles/page-styles.module.css';

export const CaseStudySlideshow = ({
  caseStudySlides
}) => {
  return (
    <section className={styles.carousel}>
      <div className={styles.carouselInner}>
        {
          caseStudySlides.map(caseStudy =>
            <CaseStudySlideshowItem
              key={caseStudy.uid}
              title={caseStudy.data.title}
              subTitle={caseStudy.data.subtitle}
              tags={caseStudy.data.tags.richText}
              description={caseStudy.data.description.richText}
              url={caseStudy.url}
              imageSrc={caseStudy.data.preview_image.gatsbyImageData}
              imageAlt={caseStudy.data.preview_image.alt}
            />
          )
        }
      </div>
    </section>
  );
}

export default CaseStudySlideshow;

import React from 'react';

import SarchEngineOptimization from '../components/seo';
import NavMain from '../components/nav-main';
import Footer from '../components/footer';

// Styles
import '../assets/styles/fonts.css';
import '../assets/styles/normalize.css';
import '../assets/styles/global.css';
import * as styles from './layout.module.css';


const Layout = ({
  children,
  title=false,
  description=false,
  image=false,
  path=false
 }) => {

  return (
    <>
      <SarchEngineOptimization
        title={title}
        description={description}
        image={image}
        path={path}
      />
      <div className={styles.page}>
        <NavMain />
        <main className={styles.layoutMain}>
          <div className={styles.layoutContent}>
            {children}
          </div>
          <Footer />
        </main>
      </div>
    </>
  );
};

export default Layout;

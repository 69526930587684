import * as React from 'react';
import cx from 'classnames';

import * as globalStyles from '../../assets/styles/page-styles.module.css';
import * as styles from './team-members.module.css';

import Container from '../container';
import TeamMember from './team-member';

const TeamMembers = ({
  title,
  teamMembers
}) => {


  return  (
    <section className={
      cx(
        globalStyles.section,
        styles.ourTeam,
      )
    }>
      <Container>
        <div className={globalStyles.sectionHeader}>
          <hr />
          <h6>{title}</h6>
        </div>

        <div className={styles.teamMembers}>
          {
            teamMembers.map((teamMember) =>
              <TeamMember 
                key={teamMember.name}
                name={teamMember.name}
                title={teamMember.title}
                photo={teamMember.photo.gatsbyImageData}
                photoAlt={teamMember.photo.alt}
                profileLink={teamMember.linkedin_profile_link.url}
              />
            )
          }
        </div>
      </Container>
    </section>
  );
}

export default TeamMembers;

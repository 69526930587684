import * as React from 'react';
import { graphql } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';

import Layout from '../../global/layout';
import { getPrevNextCaseStudyForNode } from '../../utils/case-study-utils';

import CaseStudyPageHeader from '../../components/case-study-page-header';
import CaseStudyPreview from '../../components/case-study-preview';
import Container from '../../components/container';
import { SliceZone } from '../../components/slices';

import * as styles from '../../assets/styles/page-styles.module.css';

const CaseStudyTemplate = ({ data }) => {
  if (!data) return null;
  const uid = data.prismicCaseStudy.uid;
  const doc = data.prismicCaseStudy.data;
  const additionalCaseStudies = data.allPrismicCaseStudy.nodes;

  return (
    <Layout>
      <CaseStudyPageHeader
        title={doc.page_title.richText}
        heroImage={doc.hero_image.gatsbyImageData}
        heroImageAlt={doc.hero_image.alt}
      />

      <SliceZone sliceZone={doc.body} />

      <section className={styles.additionalCaseStudies}>
        <Container>
          <hr />
          <h6>Additional Case Studies</h6>

          <div className={styles.caseStudies}>
            {
              getPrevNextCaseStudyForNode(additionalCaseStudies, uid).map(caseStudy =>
                <CaseStudyPreview
                  key={caseStudy.uid}
                  title={caseStudy.data.title}
                  subTitle={caseStudy.data.subtitle}
                  tags={caseStudy.data.tags.richText}
                  url={caseStudy.url}
                  imageSrc={caseStudy.data.preview_image.gatsbyImageData}
                  imageAlt={caseStudy.data.preview_image.alt}
                />
              )
            }
          </div>
        </Container>
      </section>
    </Layout>
  );
}

export const query = graphql`
query CaseStudyQuery($id: String) {
  prismicCaseStudy(id: { eq: $id }) {
    _previewable
    uid
    data {
      body {
        ... on PrismicCaseStudyDataBodyBannerQuote {
          slice_type
          primary {
            background_image {
              alt
              url
            }
            blockquote {
              richText
            }
            figcaption {
              richText
            }
          }
        }
        ... on PrismicCaseStudyDataBodyCaseStudySlideshow {
          slice_type
          items {
            title
            caption
            image {
              alt
              gatsbyImageData
            }
          }
        }
        ... on PrismicCaseStudyDataBodyResponsiveImage {
          slice_type
          primary {
            image {
              alt
              gatsbyImageData
            }
          }
        }
        ... on PrismicCaseStudyDataBodyTextContent {
          slice_type
          primary {
            rich_text {
              richText
            }
          }
        }
      }
      hero_image {
        alt
        gatsbyImageData
      }
      page_title {
        richText
      }
    }
  }
  allPrismicCaseStudy(
    sort: {order: ASC, fields: first_publication_date}
  ) {
    nodes {
      type
      uid
      url
      data {
        description {
          richText
        }
        title
        subtitle
        tags {
          richText
        }
        preview_image {
          alt
          gatsbyImageData
        }
      }
    }
  }
}
`

export default withPrismicPreview(CaseStudyTemplate);

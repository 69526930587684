import * as React from 'react';
import { PrismicRichText } from '@prismicio/react';

import Container from '../../components/container';
import ContentBlock from '../../components/content-block';

import * as styles from '../../assets/styles/page-styles.module.css';

const BannerQuote = ({
  slice
}) => {
  const backgroundStyle = {
    background: `#000 url(${slice.primary.background_image.url}) no-repeat`,
  };

  return (
    <section className={styles.section}>
      <Container>
        <div className={styles.slideShow} style={backgroundStyle}>
          <ContentBlock>
            <figure>
              <blockquote>
                <PrismicRichText field={slice.primary.blockquote.richText} />
              </blockquote>
              <figcaption>
                <PrismicRichText field={slice.primary.figcaption.richText} />
              </figcaption>
            </figure>
          </ContentBlock>
        </div>
      </Container>
    </section>
  );
}

export default BannerQuote;

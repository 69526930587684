import * as React from 'react';
import { PrismicRichText } from '@prismicio/react';
import { GatsbyImage } from "gatsby-plugin-image";

import ContentBlock from './content-block';
import Container from './container';

import * as styles from '../assets/styles/page-styles.module.css';

const CaseStudyPageHeader = ({
  title,
  heroImage,
  heroImageAlt
}) => {
  return (
    <section className={styles.caseStudyHeader}>
      <Container>
        <ContentBlock>
          <PrismicRichText field={title} />
        </ContentBlock>
      </Container>
      <GatsbyImage image={heroImage} alt={heroImageAlt} />
  </section>
  );
}

export default CaseStudyPageHeader;

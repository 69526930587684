module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-prismic-previews/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"seven-simple-machines","accessToken":"MC5ZZGhYM0JJQUFDNEFVOTli.M1sc77-9HVrvv73vv73vv73vv73vv73vv73vv73vv71a77-9IFjvv73vv70SIO-_ve-_ve-_ve-_ve-_vQo_HX_vv70","promptForAccessToken":true,"apiEndpoint":"https://seven-simple-machines.cdn.prismic.io/api/v2","lang":"*","pageSize":100,"imageImgixParams":{"auto":"compress,format","fit":"max","q":50},"imagePlaceholderImgixParams":{"w":100,"blur":15},"toolbar":"new"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]

import * as React from 'react';
import { graphql, Link, useStaticQuery } from "gatsby";
import { useMergePrismicPreviewData } from 'gatsby-plugin-prismic-previews';

import Container from "./container";
import SevenSMIcon from "../assets/svgs/seven-sm-icon.inline.svg";
import LinkedInLogo from "../assets/svgs/linkedin.inline.svg";
import FacebbokLogo from "../assets/svgs/facebook.inline.svg";


import * as styles  from './footer.module.css';

const Footer = () => {
  const staticData = useStaticQuery(graphql`
    query FooterNavCaseStudyQuery {
      allPrismicCaseStudy(sort: {fields: first_publication_date, order: ASC}) {
        edges {
          node {
            _previewable
            url
            data {
              title
            }
          }
        }
      }
    }
  `);

  const { data } = useMergePrismicPreviewData(staticData);
  if (!data) return null;
  const caseStudyNavItems = data.allPrismicCaseStudy.edges;

  return(
    <footer className={styles.footer}>
      <Container>
        <div className={styles.footerContent}>
          <div className={styles.brand}>
            <SevenSMIcon />
          </div>

          <div className={styles.firstRowSpacer} />

          <div className={styles.social}>
            <a href="https://www.linkedin.com/company/seven-simple-machines/" className={styles.socialLink}>
              <LinkedInLogo />
            </a>

            <a href="https://www.facebook.com/7SimpleMachines/photos/team-7sm/10161432017485383/" className={styles.socialLink}>
              <FacebbokLogo />
            </a>
          </div>

          <div className={styles.siteLinks}>
            <Link to="/">Home</Link>
            {
              caseStudyNavItems.map(navItem =>
                <Link to={navItem.node.url}>{navItem.node.data.title}</Link>
              )
            }
            <Link to="/our-vision">Vision</Link>
            <Link to="/contact">Contact</Link>
          </div>

          <div className={styles.address}>
            <p>
              7 Simple Machines<br />
              5429 Russell Ave NW #201<br />
              Seattle, Washington<br />
              98107
            </p>
          </div>

          <div className={styles.copyright}>
            <p>
              © {new Date().getFullYear()}<br />
              7 Simple Machines<br />
              <br />
              <a href="mailto:info@7simplemachines.com">
                info@7simplemachines.com
              </a>
            </p>
          </div>
        </div>
      </Container>
    </footer>
  );
}

export default Footer;

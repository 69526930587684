import { findIndex } from "lodash";

export const getPrevNextCaseStudyForNode = (caseStudies, currentCaseStudyUID) => {
  const lastCaseStudyIndex = caseStudies.length - 1;
  const currentCaseStudyIndex = findIndex(caseStudies, (o) => o.uid === currentCaseStudyUID);
  let prevCaseStudyIndex, nextCaseStudyIndex = 0;
  
  if (currentCaseStudyIndex === 0) {
    prevCaseStudyIndex = lastCaseStudyIndex;
  } else {
    prevCaseStudyIndex = currentCaseStudyIndex - 1;
  }

  if (currentCaseStudyIndex === lastCaseStudyIndex) {
    nextCaseStudyIndex = 0;
  } else {
    nextCaseStudyIndex = currentCaseStudyIndex + 1;
  }

  return [caseStudies[prevCaseStudyIndex], caseStudies[nextCaseStudyIndex]];
}

import * as React from 'react';
import { GatsbyImage } from "gatsby-plugin-image";

import Container from '../../components/container';

import * as styles from '../../assets/styles/page-styles.module.css';

const ResponsiveImage = ({
  slice
}) => {
  return (
    <section className={styles.section}>
      <Container>
        <GatsbyImage image={slice.primary.image.gatsbyImageData} alt={slice.primary.image.alt} />
      </Container>
    </section>
  );
}

export default ResponsiveImage;

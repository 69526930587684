import * as React from 'react';

import {useToggleButton} from '@react-aria/button'
import {useToggleState} from '@react-stately/toggle';

import HamburgerOpen from "../assets/svgs/hamburger-open.inline.svg";
import HamburgerClose from "../assets/svgs/hamburger-close.inline.svg";

import * as styles from './menu-toggle-button.module.css';

const MenuToggleButton = (props) => {
  let ref = React.useRef();
  let state = useToggleState(props);
  let {buttonProps} = useToggleButton(props, state, ref);

  return (
    <button
      {...buttonProps}
      className={styles.toggleButton}
      ref={ref}
    >
      {
        (state.isSelected)
        ? <HamburgerClose />
        : <HamburgerOpen />
      }
    </button>
  );
}

export default MenuToggleButton;

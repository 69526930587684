import React from 'react';
import { graphql } from 'gatsby';
import { PrismicRichText } from '@prismicio/react';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import Layout from '../global/layout';

import { FourPoints } from '../components/four-points';
import LinkButton from '../components/link-button';

import Container from '../components/container';
import ContentBlock from '../components/content-block';
import { SliceZone } from '../components/slices';

import * as styles from '../assets/styles/page-styles.module.css';

const pimPage = ({ data }) => {
  if (!data) return null;
  const doc = data.prismicPim.data;

  return (
    <Layout>
      <section className={styles.hero2}>
        <Container>
          <ContentBlock>
            <div className={styles.overlayQuote}>
              <PrismicRichText field={doc.hero_content.richText} />
            </div>
          </ContentBlock>
        </Container>
      </section>
      
      <section className={styles.section}>
        <Container>
          <ContentBlock>
            <PrismicRichText field={doc.intro_copy.richText} />
          </ContentBlock>
        </Container>
      </section>


      <Container>
        <ContentBlock>
          <FourPoints
            fourPoints={doc.body[0].items}
          />
          </ContentBlock>
      </Container>

      <SliceZone sliceZone={doc.body} />

      <section className={styles.section}>
        <Container>
          <ContentBlock>
            <br />
            <LinkButton href="/contact">Contact Us</LinkButton>
          </ContentBlock>
        </Container>
      </section>
    </Layout>
  );
};

export const query = graphql`
query PimQuery {
  prismicPim {
    _previewable
    data {
      body {
        ... on PrismicPimDataBodyBannerQuote {
          id
          slice_type
          primary {
            background_image {
              url
              alt
            }
            blockquote {
              richText
            }
            figcaption {
              richText
            }
          }
        }
        ... on PrismicPimDataBodyResponsiveImage {
          id
          slice_type
          primary {
            image {
              alt
              gatsbyImageData
            }
          }
        }
        ... on PrismicPimDataBodyTextContent {
          id
          slice_type
          primary {
            rich_text {
              richText
            }
          }
        }
        ... on PrismicPimDataBodyKeyPoints {
          id
          slice_type
          items {
            title
            support
            photo {
              alt
              gatsbyImageData
            }
          }
        }
      }
      hero_content {
        richText
      }
      hero_image {
        url
      }
      intro_copy {
        richText
      }
    }
  }
}
`

export default withPrismicPreview(pimPage);

import * as React from 'react';
import { GatsbyImage } from "gatsby-plugin-image";

import * as styles from '../../assets/styles/page-styles.module.css';

export const CaseStudySlideshowItem = ({ imageSrc, imageAlt, title, caption }) => {
  return (
    <div className={styles.caseStudyCarouselItem}>
      <div className={styles.caseStudyCarouselImage}>
        <GatsbyImage image={imageSrc} alt={imageAlt} />
      </div>
      <div className={styles.caseStudyCarouselItemCaption}>
        <div>
          <p>
            <strong>{title}</strong>
            <br />
            {caption}
          </p>
        </div>
      </div>
    </div>
  );
}

export default CaseStudySlideshowItem;

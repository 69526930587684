import * as React from 'react';
import { graphql } from 'gatsby';
import { PrismicRichText } from '@prismicio/react';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';

import Layout from '../global/layout';

import Container from '../components/container';
import ContentBlock from '../components/content-block';
import SocialIconLink from '../components/social-link';

import * as styles from '../assets/styles/page-styles.module.css';


const ContactPage = ({ data }) => {
  if (!data) return null;
  const doc = data.prismicContact.data;

  return (
    <Layout>
      <section className={styles.pageTitle}>
        <Container>
          <ContentBlock>
            <PrismicRichText field={doc.page_title.richText} />
          </ContentBlock>
        </Container>
      </section>

      <section className={styles.section}>
        <Container>
          <ContentBlock>
            <hr />
            <h2>
              Email
              <br />
              <a href={`mailto:${doc.email}`}>
                {doc.email}
              </a>
            </h2>

            <h2>
              Phone
              <br />
              {doc.phone}
            </h2>

            <h2>Connect</h2>
            <div className={styles.connectSocialLinks}>
              {
                doc.social_links.map(item =>
                  <SocialIconLink link={item.social_link.url} />
                )
              }
            </div>
          </ContentBlock>
        </Container>
      </section>

      <section className={styles.section}>
        <Container>
          <ContentBlock>
            <hr />
            <address>
              <PrismicRichText field={doc.address.richText} />
            </address>
          </ContentBlock>
        </Container>
      </section>

      <section className={styles.connectMap}>
        <iframe
          width="100%"
          height="100%"
          loading="lazy"
          className={styles.connectMapIFrame}
          title="7 Simple Machines - Map"
          src={`https://www.google.com/maps/embed/v1/place?key=${doc.google_maps_api_key}&q=place_id:${doc.google_maps_place_id}`}>
        </iframe>
      </section>
    </Layout>
  );
}

export const query = graphql`
query ContactPageQuery {
  prismicContact {
    _previewable
    data {
      address {
        richText
      }
      email
      google_maps_place_id
      google_maps_api_key
      page_title {
        richText
      }
      phone
      social_links {
        social_link {
          url
        }
      }
    }
  }
}
`

export default withPrismicPreview(ContactPage);

import * as React from 'react';
import { graphql } from 'gatsby';
import { PrismicRichText } from '@prismicio/react';
import { GatsbyImage } from "gatsby-plugin-image";
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';

import Layout from '../global/layout';

import Container from '../components/container';
import ContentBlock from '../components/content-block';
import LinkButton from '../components/link-button';
import { TeamMembers } from '../components/team-members';

import * as styles from '../assets/styles/page-styles.module.css';


const OurVisionPage = ({ data }) => {
  if (!data) return null;
  const doc = data.prismicOurVision.data;

  return (
    <Layout>
      <section className={styles.pageTitle}>
        <Container>
          <ContentBlock>
            <PrismicRichText field={doc.title.richText} />
          </ContentBlock>
          <GatsbyImage image={doc.hero_image.gatsbyImageData} alt={doc.hero_image.alt} />
        </Container>
      </section>

      <section className={styles.section}>
        <Container>
          <ContentBlock>
            <PrismicRichText field={doc.page_content.richText} />
          </ContentBlock>
        </Container>
      </section>

      <TeamMembers
        title={doc.body[0].primary.title}
        teamMembers={doc.body[0].items}
      />

      <section className={styles.contactCTA}>
        <Container>
          <ContentBlock>
            <h2>
              Shall we look under the hood?
            </h2>
            <LinkButton href="/contact">Contact Us</LinkButton>
          </ContentBlock>
        </Container>
      </section>
    </Layout>
  );
}

export const query = graphql`
  query OurVisionQuery {
    prismicOurVision {
      _previewable
      data {
        page_content {
          richText
        }
        title {
          richText
        }
        hero_image {
          gatsbyImageData
          alt
        }
        body {
          ... on PrismicOurVisionDataBodyTeamMembers {
            id
            primary {
              title
            }
            items {
              linkedin_profile_link {
                url
              }
              name
              title
              photo {
                alt
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  }
`

export default withPrismicPreview(OurVisionPage);

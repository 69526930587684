import * as  React from 'react';

import CaseStudySlideshowItem from './case-study-slideshow-item';

import * as styles from '../../assets/styles/page-styles.module.css';


const CaseStudySlideshow = ({ slice }) => {
  return (
    <section className={styles.caseStudyCarousel}>
      <hr />
      <div className={styles.caseStudyCarouselInner}>
        {
          slice.items.map(slideshowItem =>
            <CaseStudySlideshowItem
              imageSrc={slideshowItem.image.gatsbyImageData}
              imageAlt={slideshowItem.image.alt}
              title={slideshowItem.title}
              caption={slideshowItem.caption}
            />
          )
        }
        <div className={styles.caseStudyCarouselSpacer}>&nbsp;</div>
      </div>
    </section>
  );
}

export default CaseStudySlideshow;

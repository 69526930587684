import * as React from 'react';
import cx from 'classnames';
import gsap from "gsap";
import { graphql, Link, useStaticQuery } from "gatsby";
import { useMergePrismicPreviewData } from 'gatsby-plugin-prismic-previews';

import SevenSMLogo from "../assets/svgs/seven-sm-logo.inline.svg";
import MenuToggleButton from './menu-toggle-button';

import * as styles  from './nav-main.module.css';

const NavLink = (props) => {
  const { children } = props;
  return (
    <Link
      className={
        cx(
          'nav-link',
          styles.navLink
        )
      }
      activeClassName={styles.activeNavLink}
      {...props}
    >
      {children}
    </Link>
  );
}

const NavMain = () => {
  const shadeRef = React.useRef();
  const navRef = React.useRef();
  const [isOpen, setIsOpen] = React.useState(false);

  const staticData = useStaticQuery(graphql`
    query MainNavCaseStudyQuery {
      allPrismicCaseStudy(sort: {fields: first_publication_date, order: ASC}) {
        edges {
          node {
            _previewable
            url
            data {
              title
            }
          }
        }
      }
    }
  `);

  const { data } = useMergePrismicPreviewData(staticData);

  if (!data) return null;
  const caseStudyNavItems = data.allPrismicCaseStudy.edges;

  const navLinkQuery = gsap.utils.selector(navRef);

  const transitionOpen = () => {
    gsap.to(shadeRef.current, { opacity: 0.7, display: 'block'});
    gsap.to(navLinkQuery('.nav-link'), {
      stagger: 0.05,
      opacity: 1,
      duration: 0.33,
      ease: "power1.out",
    });
  }

  const transitionClose = () => {
    gsap.to(navLinkQuery('.nav-link'), {
      stagger: 0.1,
      opacity: 0,
      duration: 0.33,
    });
    gsap.to(shadeRef.current, { opacity: 0, display: 'none'});
  }

  const handleToggleMenu = (event) => {
    if (isOpen) {
      transitionClose();
    } else {
      transitionOpen();
    }
    setIsOpen(!isOpen);
  }

  const handleKeyUp = (event) => {
    if (event.code === "Escape") {
      closeMenu();
    }
  }

  const closeMenu = (event) => {
    transitionClose();
    setIsOpen(false);
  }

  return (
    <>
      <div
        onClick={closeMenu}
        onKeyUp={handleKeyUp}
        ref={shadeRef}
        role="button"
        aria-hidden
        className={
          cx(
            styles.shade,
          )
        }
      />
      <header className={
        cx(
          styles.headerLayout,
          { [styles.headerLayoutOpen]: isOpen },
        )
      }>
        <Link to="/">
          <SevenSMLogo className={styles.logo} />
        </Link>
        <nav
          className={
            cx(
              styles.navMain,
              { [styles.navMainActive]: isOpen },
            )
          }
          ref={navRef}
        >
          <ul>
            <li>
              <NavLink to="/">Home</NavLink>
            </li>
            <li>
              <NavLink to="/tpa">Dynamis</NavLink>
            </li>
            <li>
              <NavLink to={caseStudyNavItems[0].node.url}>Case Studies</NavLink>
              <ul>
                {
                  caseStudyNavItems.map(navItem =>
                    <li>
                      <NavLink to={navItem.node.url}>{navItem.node.data.title}</NavLink>
                    </li>
                  )
                }
              </ul>
            </li>
            <li>
              <NavLink to="/our-vision">Our Vision</NavLink>
            </li>
            <li>
              <NavLink to="/contact">Contact</NavLink>
            </li>
          </ul>
        </nav>
        <MenuToggleButton
          isSelected={isOpen}
          className={styles.menuToggleButton}
          onPress={handleToggleMenu}
          onKeyUp={handleKeyUp}
        />
      </header>
    </>
  );
}

export default NavMain;

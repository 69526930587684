exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-case-studies-prismic-case-study-uid-jsx": () => import("./../../../src/pages/case-studies/{PrismicCaseStudy.uid}.jsx" /* webpackChunkName: "component---src-pages-case-studies-prismic-case-study-uid-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-our-vision-jsx": () => import("./../../../src/pages/our-vision.jsx" /* webpackChunkName: "component---src-pages-our-vision-jsx" */),
  "component---src-pages-preview-jsx": () => import("./../../../src/pages/preview.jsx" /* webpackChunkName: "component---src-pages-preview-jsx" */),
  "component---src-pages-tpa-jsx": () => import("./../../../src/pages/tpa.jsx" /* webpackChunkName: "component---src-pages-tpa-jsx" */)
}


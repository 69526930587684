import * as React from 'react';
import cx from 'classnames';

import * as globalStyles from '../../assets/styles/page-styles.module.css';
import * as styles from './four-points.module.css';

import Container from '../container';
import OnePoint from './one-point';

const FourPoints = ({
  fourPoints
}) => (
    <section className={
      cx(
        globalStyles.section,
        styles.tpaPoints,
      )
    }>
      <Container>
        <div className={styles.fourPoints}>
          {
            fourPoints.map((onePoint) =>
              <OnePoint 
                key={onePoint.title}
                title={onePoint.title}
                support={onePoint.support}
                photo={onePoint.photo.gatsbyImageData}
                photoAlt={onePoint.photo.alt}
              />
            )
          }
        </div>
      </Container>
    </section>
  );

export default FourPoints;

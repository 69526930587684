import * as React from 'react'
import { BannerQuote, ResponsiveImage, RichText, CaseStudySlideshow } from '.';


export const SliceZone = ({ sliceZone }) => {
  const sliceComponents = {
    banner_quote: BannerQuote,
    responsive_image: ResponsiveImage,
    text_content: RichText,
    case_study_slideshow: CaseStudySlideshow,
  };

  const sliceZoneContent = sliceZone.map((slice, index) => {
    const SliceComponent = sliceComponents[slice.slice_type]
    if (SliceComponent) {
      return <SliceComponent slice={slice} key={`slice-${index}`} />;
    }
    return null;
  })

  return (
    <>
      {sliceZoneContent}
    </>
  );
}

export default SliceZone;

import * as React from 'react';

import LinkedInLogo from "../assets/svgs/linkedin.inline.svg";
import FacebbokLogo from "../assets/svgs/facebook.inline.svg";
import InstagramLogo from "../assets/svgs/instagram.inline.svg";
import TwitterLogo from "../assets/svgs/twitter.inline.svg";

import * as styles from '../assets/styles/page-styles.module.css';

export const SocialIconLink = ({ link }) => {
  let linkIcon = link;

  if (link.includes('facebook')) {
    linkIcon = <FacebbokLogo />;
  }

  if (link.includes('linkedin')) {
    linkIcon = <LinkedInLogo />;
  }

  if (link.includes('instagram')) {
    linkIcon = <InstagramLogo />;
  }

  if (link.includes('twitter')) {
    linkIcon = <TwitterLogo />;
  }

  return (
    <a href={link} className={styles.socialLink}>
      {linkIcon}
    </a>
  );
}

export default SocialIconLink;
